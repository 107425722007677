import React from 'react';
import BooksyIcon from 'assets/svg/booksy.svg';
import { Link } from 'gatsby';
import { Container } from '../Container/Container.styles';
import logo from '../../assets/images/homepage/logo-text.png';
import {
  FooterContent, FooterCopyright, FooterLogoWrapper, FooterWrapper,
} from './Footer.styles';

export const Footer = () => (
  <footer>
    <Container>
      <FooterWrapper>
        <FooterLogoWrapper>
          <img src={logo} alt="" />
        </FooterLogoWrapper>
        <FooterContent>
          <h3>Adres</h3>
          <p>Szosa Nakielska 19, Łochowo</p>
          <a href="/kontakt">Zobacz na mapie</a>

          <h3>Godziny otwarcia</h3>
          <p>
            Wtorek - Piątek: 9:00 - 21:00
            <br />
            Sobota: 8:00 - 16:00
            <br />
            Niedziela - Poniedziałek:
            <strong> ZAMKNIĘTE</strong>
          </p>
        </FooterContent>
        <FooterContent>
          <h3>Kontakt</h3>
          <a href="tel:+48884303797">
            <span>Telefon: 884 303 797</span>
          </a>
          <a href="mailto:a.kurlapska@wp.pl" target="_blank" rel="noreferrer">
            <span>E-mail: a.kurlapska@wp.pl</span>
          </a>
          <a href="https://booksy.com/pl-pl/158998_look-royal-agnieszka-kurlapska_medycyna-estetyczna_22068_bydgoszcz" target="_blank" rel="noreferrer">
            <span>
              Rezerwacje:
            </span>
            <BooksyIcon />
          </a>
          <Link to="/rodo">RODO</Link>
        </FooterContent>
      </FooterWrapper>
    </Container>
    <FooterCopyright>
      <Container>
        <p>Copyright © 2023 lookroyal.pl</p>
      </Container>
    </FooterCopyright>
  </footer>
);
