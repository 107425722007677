import styled from 'styled-components';

export const CookiesContainer = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: fixed;
  padding: 10px 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  transform: ${({ isTransitioned }) => (isTransitioned ? 'translateY(0)' : 'translateY(100%)')};
  background-color: ${({ theme }) => theme.color.lightGold};
  transition: transform .3s ease-in-out;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    margin: 0 10px 0 0;
    font-size: 1.2rem;

    ${({ theme }) => theme.mq.bigDesktop} {
      font-size: 1.6rem;
    }
  }

  button {
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.darkGray};
    font-family: ${({ theme }) => theme.font.family.fira};
    font-size: 1.6rem;
    font-weight: 600;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    transition: background-color .3s ease-in-out, color .3s ease-in-out;

    &:hover{
      background-color: ${({ theme }) => theme.color.darkGray};
      color: ${({ theme }) => theme.color.lightGold};
      cursor: pointer;
    }
  }
`;
