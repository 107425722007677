export const theme = {
  color: {
    darkGray: '#21221B',
    creme: '#FAF3E5',
    veryLightGold: '#f4e6c8',
    lightGold: '#E8CD94',
    darkGold: '#D8AA46',
    transparentGold: 'rgba(216, 170, 70, 0.6);',
    goldGradient: 'linear-gradient(90.01deg, rgba(216, 170, 70, 0.75) 28.75%, rgba(232, 205, 148, 0.375) 99.99%);',
    verticalGoldGradient: 'linear-gradient(0deg, rgba(216,170,70,0.75) 0%, rgba(255,255,255,1) 80%);',
    blackOverlay: 'rgba(0, 0, 0, 0.5)',
    white: '#FFFFFF',
    black: '#000000',
  },
  size: {
    desktopHeroHeight: '800px',
  },
  font: {
    shadow: '5px 5px 10px hsla(0, 0%, 0%, 0.15)',
    size: {
      heading: '5rem',
      headingMobile: '5.3rem',
      headingSmall: '4.2rem',
      paragraph: '1.5rem',
      caption: '1.35rem',
      mobileMenu: '2.1rem',
      button: '1.2rem',
      thumbnail: '1.8rem',
      thumbnailSmall: '1.4rem',
    },
    family: {
      fira: '"Fira Sans", sans-serif',
      cormorant: '"Cormorant Garamond", serif',
    },
  },
  mq: {
    mobile: '@media (min-width: 540px)',
    tablet: '@media (min-width: 768px)',
    desktop: '@media (min-width: 1024px)',
    bigDesktop: '@media (min-width: 1360px)',
    huge: '@media(min-width: 1440px)',
  },
  containerWidth: {
    mobile: '520px',
    tablet: '748px',
    desktop: '980px',
    bigDesktop: '1320px',
    huge: '1400px',
  },
};
