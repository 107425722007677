import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { SubMenuListItem } from './SubMenuListItemWrapper.styles';

export const SubMenuListItemWrapper = ({ children, title }) => {
  const [height, setHeight] = useState(0);
  const submenuHeight = useRef();

  const toggleAccordion = () => {
    setHeight(height === 0 ? submenuHeight.current.scrollHeight : 0);
  };

  return (
    <SubMenuListItem onClick={toggleAccordion} onKeyDown={toggleAccordion} height={height}>
      <div><span>{title}</span></div>
      <ul ref={submenuHeight}>
        {children}
      </ul>
    </SubMenuListItem>
  );
};

SubMenuListItemWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};
