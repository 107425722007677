exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cennik-jsx": () => import("./../../../src/pages/cennik.jsx" /* webpackChunkName: "component---src-pages-cennik-jsx" */),
  "component---src-pages-galeria-jsx": () => import("./../../../src/pages/galeria.jsx" /* webpackChunkName: "component---src-pages-galeria-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-makijaz-permanentny-jsx": () => import("./../../../src/pages/makijaz-permanentny.jsx" /* webpackChunkName: "component---src-pages-makijaz-permanentny-jsx" */),
  "component---src-pages-medycyna-estetyczna-efekt-btx-jsx": () => import("./../../../src/pages/medycyna-estetyczna-efekt-btx.jsx" /* webpackChunkName: "component---src-pages-medycyna-estetyczna-efekt-btx-jsx" */),
  "component---src-pages-medycyna-estetyczna-kwas-hialuronowy-jsx": () => import("./../../../src/pages/medycyna-estetyczna-kwas-hialuronowy.jsx" /* webpackChunkName: "component---src-pages-medycyna-estetyczna-kwas-hialuronowy-jsx" */),
  "component---src-pages-prezent-jsx": () => import("./../../../src/pages/prezent.jsx" /* webpackChunkName: "component---src-pages-prezent-jsx" */),
  "component---src-pages-rodo-jsx": () => import("./../../../src/pages/rodo.jsx" /* webpackChunkName: "component---src-pages-rodo-jsx" */)
}

