import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    
  }
  
  html {
    font-size: 62.5%;
  }
  
  body {
    margin: 0;
    font-size: 1.6rem;
    color: #21221B;

    @media (min-width: 1024px){
      overflow: auto !important;
    }

    &::-webkit-scrollbar {
      width: 5px;

      @media (min-width: 1024px){
        width: 10px;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #D8AA46;
      border-radius: 10px;
    }
  }
  
  h1,h2,h3,h4,h5,h6 {
    font-family: "Cormorant Garamond", serif;
    font-weight: 500;
    line-height: 1.2;
  }
  
  p, li, a {
    font-family: "Fira Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.6rem;
  }

  img {
    width: 100%;
    height: auto;
  }
`;
