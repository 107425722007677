import styled from 'styled-components';

export const FooterWrapper = styled.div`
  margin: 30px 0;
  text-align: center;

  ${({ theme }) => theme.mq.tablet} {
    display: grid;
    grid-template-columns: 1fr 1.3fr 1fr;
    justify-items: center;
    text-align: left;
    grid-gap: 30px;
    margin: 50px 0;
  }
`;

export const FooterLogoWrapper = styled.div`
  max-width: 315px;
  margin: 0 auto 30px;
`;

export const FooterContent = styled.div`
  h3 {
    font-family: ${({ theme }) => theme.font.family.fira};
    font-weight: 600;
    font-size: 1.8rem;
    margin: 25px 0 10px;

    ${({ theme }) => theme.mq.tablet} {
      &:first-child{
        margin: 0 0 10px;
      }
    }
  }

  p {
    margin: 5px 0;
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    color: ${({ theme }) => theme.color.darkGray};
    transition: color .3s ease-in-out;
    justify-content: center;
    padding: 10px 0;

    ${({ theme }) => theme.mq.tablet} {
      justify-content: flex-start;
      padding: 0;
    }

    &:hover {
      color: ${({ theme }) => theme.color.darkGold};

      svg path {
        fill: ${({ theme }) => theme.color.darkGold};
      }
    }

    svg {
      margin-left: 10px;
      max-width: 80px;
      width: 100%;

      path {
        transition: fill .3s ease-in-out;
      }
    }
  }
`;

export const FooterCopyright = styled.div`
  background-color: ${({ theme }) => theme.color.lightGold};
  padding: 15px 0;
  text-align: center;

  p {
    font-size: 1.2rem;
    font-weight: 600;
  }
`;
