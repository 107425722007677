import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import throttle from 'lodash.throttle';
import logo from '../../assets/images/homepage/logo-text.png';
import { SubMenuListItemWrapper } from './SubMenuListItemWrapper';
import {
  HeaderButton, HeaderContainer, OuterWrapper, StyledBurger,
  StyledLogo, StyledNavigation, Wrapper,
} from './Navigation.styles';

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleNavigation = () => {
    setIsOpen(!isOpen);
  };

  const closeNavigation = () => {
    setIsOpen(false);
  };

  const handleScroll = throttle(() => {
    if (window.scrollY > 20 && !isScrolled) {
      setIsScrolled(true);
    }
    if (window.scrollY === 0 && isScrolled) {
      setIsScrolled(false);
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  return (
    <header>
      <OuterWrapper>
        <HeaderContainer isScrolled={isScrolled}>
          <StyledLogo to="/" isSmall isMobile>
            <img src={logo} alt="Look-royal logo" />
          </StyledLogo>
          <StyledBurger onClick={toggleNavigation} isOpen={isOpen} aria-controls="menu">
            <div></div>
          </StyledBurger>
          <Wrapper isOpen={isOpen}>
            <StyledLogo to="/" onClick={closeNavigation}>
              <img src={logo} alt="Look-royal logo" />
            </StyledLogo>
            <StyledNavigation role="navigation" aria-label="Main menu">
              <ul id="menu">
                <li>
                  <Link to="/makijaz-permanentny" onClick={closeNavigation}>Makijaż permanetny</Link>
                </li>
                <SubMenuListItemWrapper title="Medycyna estetyczna">
                  <li>
                    <Link to="/medycyna-estetyczna-efekt-btx" onClick={closeNavigation}>Efekt BTX</Link>
                  </li>
                  <li>
                    <Link to="/medycyna-estetyczna-kwas-hialuronowy" onClick={closeNavigation}>Kwas hialuronowy</Link>
                  </li>
                </SubMenuListItemWrapper>
                <SubMenuListItemWrapper title="Cennik">
                  <li>
                    <Link to="/cennik" state={{ cennik: 'makijaz' }} onClick={closeNavigation}>Makijaż permanetny</Link>
                  </li>
                  <li>
                    <Link to="/cennik" state={{ cennik: 'medycyna' }} onClick={closeNavigation}>Medycyna estetyczna</Link>
                  </li>
                </SubMenuListItemWrapper>
                <li>
                  <Link to="/galeria" onClick={closeNavigation}>Galeria</Link>
                </li>
                <li>
                  <Link to="/prezent" onClick={closeNavigation}>Prezent</Link>
                </li>
                <li>
                  <Link to="/kontakt" onClick={closeNavigation}>Kontakt</Link>
                </li>
              </ul>
            </StyledNavigation>
            <HeaderButton onClick={closeNavigation}>
              <a href="https://booksy.com/pl-pl/158998_look-royal-agnieszka-kurlapska_medycyna-estetyczna_22068_bydgoszcz" target="_blank" rel="noreferrer">Umów wizytę</a>
            </HeaderButton>
          </Wrapper>
        </HeaderContainer>
      </OuterWrapper>
    </header>
  );
};
